/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Home from 'templates/page'

export const pageQuery = graphql`
  query homeById {
    page: wpPage(id: { eq: "cG9zdDoy" }) {
      ...generalPageFragment
    }
  }
`

const HomeTemplate = (data: any) => <Home {...data} />

export default HomeTemplate
